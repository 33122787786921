const KEY_PREFIX = 'PEOPLE_FORM';

const getStorageKey = (key: string): string => {
  return `${KEY_PREFIX}_${key.replace(/-/g, '_').toUpperCase()}`;
};

export const createStorage = (storage: Storage) => ({
  setItem(key: string, value: any): void {
    try {
      const storageKey = getStorageKey(key);
      const data = JSON.stringify(value);
      storage.setItem(storageKey, data);
    } catch (error) {
      console.error('Error setting data in storage:', error);
    }
  },
  getItem(key: string): any {
    try {
      const storageKey = getStorageKey(key);
      const data = storage.getItem(storageKey);
      return data ? JSON.parse(data) : null;
    } catch (error) {
      console.error('Error getting data from storage:', error);
      return null;
    }
  },
  removeItem(key: string): void {
    try {
      const storageKey = getStorageKey(key);
      storage.removeItem(storageKey);
    } catch (error) {
      console.error('Error removing data from storage:', error);
    }
  },
  clear(): void {
    try {
      storage.clear();
    } catch (error) {
      console.error('Error clearing storage:', error);
    }
  }
});

export const SessionStorage = createStorage(sessionStorage);
export const LocalStorage = createStorage(localStorage);

export const clearStorage = (): void => {
  LocalStorage.clear();
  SessionStorage.clear();
};

export const setTokenStorage = (token = ''): void => {
  LocalStorage.setItem('token', token);
};

export const setCsrfToken = (csrfToken = ''): void => {
  LocalStorage.setItem('csrf_token', csrfToken);
};

export const setCurrentUser = (user: any): void => {
  SessionStorage.setItem('user', user);
};

export const setLastDocument = (document = ''): void => {
  SessionStorage.setItem('lastDocument', document);
};

export const setCurrentAccount = (account = ''): void => {
  SessionStorage.setItem('current_account', account);
};

export const setCurrentOrganization = (organization = ''): void => {
  SessionStorage.setItem('originOrganizationId', organization);
};

export const setPeoplePositionId = (peoplePositionId = ''): void => {
  SessionStorage.setItem('originPositionId', peoplePositionId);
};

export const getTokenStorage = (): string | null => {
  return LocalStorage.getItem('token');
};

export const getCsrfToken = (): string | null => {
  return LocalStorage.getItem('csrf_token');
};

export const getCurrentUser = (): any => {
  return SessionStorage.getItem('user') || {};
};

export const getLastDocument = (): string | null => {
  return SessionStorage.getItem('lastDocument');
};

export const getCurrentAccount = (): string | null => {
  return SessionStorage.getItem('current_account');
};

export const getCurrentOrganization = (): string | null => {
  return SessionStorage.getItem('originOrganizationId');
};

export const getPeoplePositionId = (): string | null => {
  return SessionStorage.getItem('originPositionId');
};
