import { LaunchdarklyService } from "@acesso-io/launchdarkly-client-ts/lib/launchdarkly";
import { Environment } from "@app/core/_shared/interfaces/environment/enviroment.interface";
import { getCurrentOrganization, getPeoplePositionId, SessionStorage } from "@app/_shared/services/storage/storage.service";
import * as ld from 'launchdarkly-js-client-sdk';
import mixpanel from "mixpanel-browser";

declare const environment: Environment;

const LAUNCH_DARKLY_SHARED_KEY = '00000000-0000-0000-0000-000000000000';

/**
 * Initializes the LaunchDarkly service with the organization key if it exists in the local storage.
 * Otherwise it initializes the service with a context identified by a shared key.
 * @param launchDarklyService LaunchDarkly service
 * @returns Promise
 */
function initializeLaunchDarkly(launchDarklyService: LaunchdarklyService) {
    const position = getPeoplePositionId();
    const organization = getCurrentOrganization();
    if (organization) {
      const identifiedContext = {
        kind: 'multi',
        organization: {
          key: organization,
        },
        user: {
          key: position,
          position,
          appName: 'form',
        },
      } as ld.LDMultiKindContext;

      return launchDarklyService.init({ context: identifiedContext });
    }

    const sharedContext = {
      kind: 'user',
      key: LAUNCH_DARKLY_SHARED_KEY,
      appName: 'form',
    } as ld.LDSingleKindContext;

    return launchDarklyService.init({ context: sharedContext });
}

function initializeMixpanel() {
  mixpanel.init(
    environment.mixpanelToken,
    { debug: !environment.production, track_pageview: true, persistence: 'localStorage' }
  );
}

export default function initializeAppFactory(launchDarklyService: LaunchdarklyService) {
  return async () => {
    await initializeLaunchDarkly(launchDarklyService);

    initializeMixpanel();
  };
}
