import { Injectable } from '@angular/core';
import { getCurrentOrganization, getCurrentUser, SessionStorage } from '@app/_shared/services/storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class DocumentsTrackingSegmentService {
    docsToTrack = {
        'rg': 'rg',
        'cpf': 'cpf',
        'endereco': 'comprovante_endereco',
        'pis': 'pis',
        'infos-pessoais': 'info_pessoais',
        'escolaridade': 'comprovante_escolaridade',
        'estrangeiro': 'doc_estrangeiro',
        'certidao-casamento': 'certidao_casamento',
        'certidao-nascimento': 'certidao_nascimento',
        'certidao-divorcio': 'certidao_divorcio',
        'certidao-viuvo': 'certidao_viuvez',
        'certidao-separado': 'certidao_separacao',
        'certidao-uniao-estavel': 'certidao_uniao_estavel',
        'responsaveis': 'responsaveis_legais',
        'dependentes': 'dependentes',
        'laudo-medico': 'laudo_medico',
        'address': 'comprovante_endereco',
        'comprovante-vacinacao-covid': 'covid',
        'exame-medico': 'exame_medico',
        'completed': 'documentos_validados',
        'success': 'em_analise',
        'tcp': 'tcp',
        'mopp': 'mopp',
        'passaporte': 'passaporte',
        'curso_tecnico': 'curso_tecnico',
        'sbv': 'sbv',
        'cota-pcd': 'cota_pcd',
        'documents': 'envio',
    };

  public shouldTrackDocument(): boolean {
    return !!this.docsToTrack[this.getLocationDocument()];
  }

  public getLocationDocument(): string {
    const locationUrl = window.location.pathname;

    if(locationUrl.lastIndexOf('/document/') != -1) {
      let locationDocument = locationUrl.substring(locationUrl.lastIndexOf('/document/') + 10);
      locationDocument =
        locationDocument.indexOf('/new') > -1 ? locationDocument.substring(0, locationDocument.indexOf('/new')) : locationDocument;
        if (locationDocument.includes('schema/')) locationDocument = locationDocument.replace('schema/', '')
      return locationDocument;
    } else {
      return locationUrl.split('/')[1]
    }
  }

  public getLocationDocumentAlias(): string {
    let locationDocument = this.docsToTrack[this.getLocationDocument()];
    if (locationDocument == 'comprovante_endereco') {
      return SessionStorage.getItem('screen') || locationDocument;
    }
    return locationDocument;
  }

  public getOrganizationName(): string {
    const user = getCurrentUser();
    return user ? user.organizationName : '';
  }

  public getAccountName(): string {
    const infos = SessionStorage.getItem('onboardingInfos');
    return infos ? infos.account?.name : '';
  }

    public getEventTitle() {
        return {
            save: 'People - Candidate Admission Document - Save Button Click',
            back: 'People - Candidate Admission Document - Back Button Click',
            upload: 'People - Candidate Admission Document - Take Photo Link Click',
            finishLater: 'People - Candidate Admission Document - Finish Later Button Click',
            pageView: 'People - Candidate Admission Document - Page View',
            signedIn: 'People - Candidate Admission - User Signed In',
            signedUp: 'People - Candidate Admission - User Signed Up',
            positionNull: 'People - Candidate Admission - Position Null Experiment',
        }
    }

  public getProperties(location?) {
    return {
      organization: this.getOrganizationName(),
      account: this.getAccountName(),
      location: location || this.getLocationDocumentAlias(),
      organization_id: getCurrentOrganization()
    };
  }
}
