import { Injectable, ErrorHandler } from '@angular/core';
import * as Sentry from "@sentry/angular-ivy";
import { Extra, ScopeContext } from '@sentry/types';
import { PeopleUser } from '@app/_shared/models/people-user';
import { HttpErrorResponse } from '@angular/common/http';
import { Environment } from '@app/core/_shared/interfaces/environment/enviroment.interface';
import { DocumentsTrackingSegmentService } from '@app/modules/documents/_shared/services/document/documents-tracking-segment.service';
import { getCurrentOrganization, getPeoplePositionId, SessionStorage } from '@app/_shared/services/storage/storage.service';

declare const environment: Environment;

@Injectable({
    providedIn: 'root',
})
export class GlobalErrorHandler implements ErrorHandler {
    private readonly production: boolean = environment.production;
    private readonly sentryIgnoreStatusCodeErrors: string = environment.sentry_ignore_status_code_errors;

    handleError(error: any): void {
      console.error(error)

      const chunkFailedMessage = /Loading chunk [\d]+ failed/;
      if (chunkFailedMessage.test(error.message)) {
          window.location.reload();
      }

      if (!this.production) {
        return
      }

      const context = this.makeContext();
      if (error instanceof HttpErrorResponse) {
        if(this.hasSendHttpErrorEvent(error)) {
          context.extra["error"] = {raw: error?.error, status: error?.status}
          Sentry.captureMessage(error.message, context)
        }
      } else {
        Sentry.captureException(error, context)
      }
    }

    private hasSendHttpErrorEvent(error: HttpErrorResponse): boolean {
      let send = true
        for (const code in this.sentryIgnoreStatusCodeErrors?.split(",")) {
          if (error?.status?.toString() === code) {
            send = false
            break
          }
      }

      return send
    }

    private getPeopleUser(): PeopleUser | undefined {
      const data = SessionStorage.getItem("user")
      if(data) {
        try {
           return JSON.parse(data) as PeopleUser
        } catch (error) {
          // pass
        }
      }
      return undefined
    }

    private makeContext() {
      const track = new DocumentsTrackingSegmentService()
      const user = this.getPeopleUser();
      const context = {
        level: 'error',
        extra: {
          "organization": {
            id: getCurrentOrganization(),
            name: track.getAccountName()
          },
          "candidateUser": {
            id: user?.id,
          },
          "position": getPeoplePositionId(),
        } as Extra,
      } as ScopeContext;

      if (user) {
        context.user = {} as Sentry.User;
        context.user.email = user?.email;
        context.user.id = getCurrentOrganization();
        context.user.username = user?.name;
      }
      return context;
    }
}
